<template>
    <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" :width="'1000px'">
        <jy-query ref="checkForm" :model="formData">
            <jy-query-item label="车牌号" prop="vehicleNo">
                <el-input placeholder="请输入" v-model="formData.vehicleNo"></el-input>
            </jy-query-item>
            <jy-query-item label="自编号" prop="customNo">
                <el-input placeholder="请输入" v-model="formData.customNo"></el-input>
            </jy-query-item>
            <template #rightCol>
                <el-button type="primary" v-on:click="search">查询</el-button>
                <el-button type="primary" v-on:click="reset">重置</el-button>
            </template>
        </jy-query>
        <div style="font-size:16px;">合计：<span style="color:red;">{{shiteNumTotal}}</span> 班次</div>
        <jy-table v-if="dialogVisible" :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="sd" label="开始日期" min-width="120"></jy-table-column>
            <jy-table-column prop="ed" label="结束日期" min-width="120"></jy-table-column>
            <jy-table-column prop="routeNa" label="线路" min-width="120"></jy-table-column>
            <jy-table-column prop="customNo" label="自编号" min-width="120"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号" min-width="120"></jy-table-column>
            <jy-table-column prop="shiteNum" label="班次数" min-width="100"></jy-table-column>
            <jy-operate :list="butList" width="100"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <detailForVehicle ref="detailForVehicle"></detailForVehicle>
    </jy-dialog>
</template>
<script>
import detailForVehicle from './detailForVehicle.vue'
export default {
    data() {
        return {
            title: '车辆班次统计',
            dialogVisible: false,
            butList: [],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            shiteNumTotal:0,
            formData: {
                vehicleNo: '',
                customNo: '',
            }
        }
    },
    components:{detailForVehicle},
    methods: {
        init(option) {
            this.formData = {
                ...this.formData,
                ...option
            }
            this.pageIndex = 1;
            this.pageSize = 10;
            this.getList()
            this.setButList()
            this.dialogVisible = true
        },
        setButList() {
            let list = [{
                name: '明细',
                fun: (row) => {
                    console.log(row)
                    let params = {
                        routeId: this.formData.routeId || '',
                        vehicleNo: row.vehicleNo || '',
                        customNo: row.customNo || '',
                    }
                    params.startDate = this.formData.startDate
                    params.endDate = this.formData.endDate
                    this.$refs.detailForVehicle.init(params)
                },
                isShow: () => { return true }
            }]
            this.butList = list
        },
        search() {
            this.getList()
        },
        reset() {
            this.$refs.checkForm.resetFields()
            this.getList()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        getList() {
            let url = '/waybillStatistics/statisticsByVehicleId'
            let params = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                ...this.formData
            }
            Object.keys(params).forEach(key => {
                if (/^\s*$/.test(params[key])) {
                    delete params[key]
                }
            })
            this.$http.post(url, params).then(({ detail }) => {
                this.shiteNumTotal = detail.shiteNumTotal
                this.total = detail.waybillStatisVOList.total
                this.dataList = detail.waybillStatisVOList.list.map(one => {
                    one.sd = params.startDate
                    one.ed = params.endDate
                    return one
                })
            })
        },
    }
}

</script>
